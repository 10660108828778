import {applyMiddleware, combineReducers, createStore} from 'redux';
import {routerMiddleware, routerReducer} from 'react-router-redux';
import rootReducer from './application/reducers/reducer';

import thunk from 'redux-thunk';
import history from './history';

const createAppStore = (history) => {
  const reducers = combineReducers({
    router: routerReducer,
    root: rootReducer,
  });

  const middleWares = [
    thunk,
    routerMiddleware(history)
  ];

  return createStore(
    reducers,
    applyMiddleware(...middleWares)
  );
};

const AppStore = createAppStore(history);
AppStore.history = history;
export default AppStore;

