import React from 'react';
import 'antd/lib/spin/style/css'
import './loading.scss';
import Spin from 'antd/lib/spin/index';
class Loading extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {text, loadingHeight, backgroundColor, style} = this.props;
        return (
            <div className="loading"
                 style={Object.assign({
                     height: loadingHeight,
                     '--n': '5',
                     backgroundColor,
                     borderRadius: 3,
                     flex: 1
                 }, style)}>
                <Spin tip={text}>
                </Spin>
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <span className="dot" style={{ '--i': '0' }} />
            <span className="dot" style={{ '--i': '1' }} />
            <span className="dot" style={{ '--i': '2' }} />
            <span className="dot" style={{ '--i': '3' }} />
            <span className="dot" style={{ '--i': '4' }} />
          </div>
          <div style={{ color: '#555', fontSize: 13, paddingTop: 3 }}>
            {text}
          </div>
        </div> */}
            </div>
        );
    }
}

export default Loading;

Loading.defaultProps = {
    text: "Loading...",
    loadingHeight: '100%',
    backgroundColor: "#fff"
};