import menuConstants from "../constants/menuConstants";

export const onChangeSelects = (selectKeys) => {
    // const isPermisstion = selectKeys ? true : false;
    return {
        type: menuConstants.CHANGE_SELECT_MENU,
        payload: selectKeys
    }
};

export const onChangeOpenKeys = (openKeys) => {
    return {
        type: menuConstants.CHANGE_OPEN_KEY_MENU,
        payload: openKeys
    }
};

export const forceSelectMenu = (selectKeys) => {
    return {
        type: menuConstants.CHANGE_SELECT_MENU,
        payload: selectKeys
    }
};

// load all items
export const loadMenu = (dataMenu) => {
    return {
        type: menuConstants.LOAD_MENU,
        payload: dataMenu
    }
};