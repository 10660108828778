import React from 'react';
import {ConfigGlobal, t} from "core/idtek-component";
import 'core/idtek-icon';
import Loading from './pages/Loading';
import {Empty} from 'antd';

ConfigGlobal.positionNotification = 'bottomRight';
ConfigGlobal.loading = <Loading/>;
ConfigGlobal.loadingForm = <Loading text="Loading..."/>;
ConfigGlobal.loadingGrid = <Loading text="Loading..."/>;
ConfigGlobal.gridSkeleton = true;
ConfigGlobal.gridSkeletonDeplay = 100;
ConfigGlobal.emptyComponent = <Empty description={t("No data")}/>;
ConfigGlobal.store = {
    separator: '[]'
};