import {LanguageContext, LanguageDictionary} from "core/idtek-component";
import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route} from "react-router-dom";
import "./ConfigGlobal";
import history from "./history";
import "./index.scss";
import AppStore from "./store";
import 'font-awesome/css/font-awesome.min.css';
import AppUtil from "./utils/AppUtil";
import _ from "lodash";
import moment from 'moment'
import 'application/css/rc-select.scss'

const LayoutPage = React.lazy(() => import("./application/components/Layout"));
const ClosingPage = React.lazy(() => import("./ClosingPage"));
const LogoutPage = React.lazy(() => import("./LogoutPage"));

class App extends React.Component {
    static contextType = LanguageContext

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
        this.defaultLanguage = AppUtil.GLOBAL_DATE_LOCALE
    }


    componentDidMount() {
        this.loadLanguage()
    }

    getApiNotReload = async (api, params) => {
        const url = api;
        return await AppUtil.getApi(url, params).catch((error) => {
        });
    };

    async loadLanguage() {
        const { userLanguageChange } = this.context;
        const res = await this.getApiNotReload(`/branch/get-language`, {});
        if (_.get(res, "data.success")) {
            const result = _.get(res.data, "result")

            if (_.get(result, 'language') && !_.isEmpty(_.get(result, 'dictionary'))) {
                AppUtil.GLOBAL_DATE_LOCALE = 'vi'
                moment.locale('vi')
                LanguageDictionary.setDictionary(
                    result.dictionary,
                    'vi'
                );
                userLanguageChange('vi');

            } else {
                this.setDefault()
            }


            //date
            if (_.get(result, 'dateFormat')) {
                AppUtil.GLOBAL_DATE_FORMAT = result.dateFormat
            }
            if (_.get(result, 'dateTimeFormat')) {
                AppUtil.GLOBAL_DATETIME_FORMAT = result.dateTimeFormat
            }
            this.setState({
                loading: false
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }


    setDefault() {
        const dictionaryDefault = require(`./language/${this.defaultLanguage}.json`);
        LanguageDictionary.setDictionary(
            dictionaryDefault,
            this.defaultLanguage
        );
        moment.locale('vi')
    }


    render() {
        if (this.state.loading) return <></>
        return (
            <React.Suspense fallback={<div />}>
                <div className="App" style={{ height: "100%" }}>
                    <Provider store={AppStore}>
                        <Router history={history}>
                            <Route path="/closing" exact={true} component={ClosingPage} />
                            <Route path="/logout" exact={true} component={LogoutPage} />
                            <Route path="/" component={LayoutPage} />
                        </Router>
                    </Provider>
                </div>
            </React.Suspense>

        );
    }
}

export default App;
