import React, { Component } from 'react';
import App from "./App";
import { LanguageProvider } from "core/idtek-component";

class Main extends Component {
    render() {
        return (
            <LanguageProvider defaultLanguage={"vi"}>
                <App />
            </LanguageProvider>
        );
    }
}

export default Main;