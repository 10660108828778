import * as Constant from "../constants/constant";
import _ from "lodash";
import TreeUtil from "../../utils/TreeUtil";

export const setConfigAppBeauty = (payload) => {
    return {
        type: Constant.SET_CONFIG_APP,
        payload
    };
};

export function setAxiosStop(bool) {
    return {
        type: Constant.SET_AXIOS_STOP,
        bool
    };
}

export const routeChanged = () => {
    return {type: Constant.ROUTE_CHANGED};
};

export const logout = () => {
    return {type: Constant.LOG_OUT};
};

export const logoutSuccessFull = () => {
    return {type: Constant.LOG_OUT_SUCCESSFUL};
};

export const loginSuccessFull = (authData, menuConfig) => {
    return {
        type: Constant.LOG_IN_SUCCESSFUL,
        authData,
        menuConfig
    };
};

export const changeRight = (rights) => {
    return {
        type: Constant.CHANGE_PTW_RIGHT,
        rights
    };
};

export const changeBranch = (branchOptions) => {
    return {
        type: Constant.CHANGE_BRANCH_OPTIONS,
        branchOptions
    };
};

export const setBranch = (branch) => {
    return {
        type: Constant.SET_BRANCH,
        branch
    };
};

export const setGst = (value) => {
    return {
        type: Constant.CHANGE_GST,
        value
    };
};

export const changeUserCreatedBy = (userCreatedBy) => {
    return {
        type: Constant.CHANGE_USER_CREATED_BY,
        userCreatedBy
    };
};

export function toggleMenuDesktop(show) {
    return {
        type: Constant.TOGGLE_MENU_DESKTOP,
        show
    };
}

export function changeDialog(dialog) {
    return {
        type: Constant.CHANGE_DIALOG,
        dialog
    };
}

export function changeBreadcrumb(breadcrumbs) {
    return {
        type: Constant.CHANGE_BREADCRUMB,
        breadcrumbs
    };
}

export function changeSideMenu(menu) {
    return {
        type: Constant.CHANGE_SIDEMENU,
        menu
    };
}

export function setHeaderMenu(menu) {
    return {
        type: Constant.SET_HEADER_MENU,
        menu
    };
}

export function showSideBarMenu(bool) {
    return {
        type: Constant.SHOW_SIDE_BAR_MENU,
        bool
    };
}

export function changeIsPermisstion(isPermisstion) {
    return {
        type: Constant.CHANGE_IS_PERMISSTION,
        isPermisstion
    };
}

export function loadComboboxDataBookingForm(comboboxData) {
    return {
        type: Constant.LOAD_COMBOBOX_DATA_BOOKING_FORM,
        comboboxData
    };
}

export function loadComboboxPriotityTechnician(combo, isDefault) {
    return {
        type: Constant.LOAD_COMBOBOX_PRIORITY_TECHNICIAN,
        combo
    };
}

export function loadTechnicianWorkingSchedule(combo) {
    return {
        type: Constant.LOAD_TECHNICIAN_WORKING_SCHEDULE,
        combo
    };
}

export function loadConfigDashboardV2(combo) {
    return {
        type: Constant.LOAD_CONFIG_DASHBOARD_V2,
        combo
    };
}

export function loadTaskSchedule(combo) {
    return {
        type: Constant.LOAD_TASK_SCHEDULE,
        combo
    };
}

export function loadTechnicianSkillStore(combo) {
    return {
        type: Constant.LOAD_TECHNICIAN_SKILL_STORE,
        combo
    };
}

export function loadBookingStatusStore(combo) {
    return {
        type: Constant.LOAD_BOOKING_STATUS,
        combo
    };
}

export function loadCustomerStore(combo) {
    return {
        type: Constant.LOAD_CUSTOMER_STORE,
        combo
    };
}

export function loadServiceStore(combo) {
    return {
        type: Constant.LOAD_SERVICE_STORE,
        combo
    };
}

export function loadAllServiceStore(combo) {
    return {
        type: Constant.LOAD_ALL_SERVICE_STORE,
        combo
    };
}

export function resetServiceStore() {
    return {
        type: Constant.RESET_SERVICES
    };
}

export function updateBooking(booking, dataReordering) {
    return {
        type: Constant.UPDATE_BOOKING,
        booking
    };
}

export function updateCustomer(combo) {
    return {
        type: Constant.UPDATE_CUSTOMER,
        combo
    };
}

export function updateBrandInAuthData(authData, value) {
    return {
        type: Constant.UPDATE_BRAND_IN_AUTH_DATA,
        value,
        authData
    };
}

export function selectManyService(stores, dataServices) {
    let dataReordering = [];
    _.each(dataServices, (item) => {
        const data = {
            code: item.code,
            id: `service_${item.service_id}`,
            name: item.name,
            order: item.order,
            price: item.price,
            service_group_id: item.service_group_id,
            service_id: item.service_id,
            status: item.status,
            technician: item.technician_id,
            technicianName: item.technician_name,
            time: item.time,
            startTime: item.startTime,
            endTime: item.endTime
        };
        dataReordering.push(data);
    });
    const allGroupIds = _.uniq(_.map(stores.allServiceStore, (x) => x.service_group_id));
    const diff = _.filter(_.differenceBy(stores.allServiceStoreFlatData, dataReordering, "id"), (item) => _.indexOf(allGroupIds, item.service_group_id) >= 0);
    stores.servicesStore = _.filter(diff, (x) => x.type !== "service_group");
    stores.allServiceStore = TreeUtil.buildTreeParentId(diff, "id", "parentId", "children");
    const totalAmount = getTotalAmount(dataReordering),
        totalTime = getTotalTime(dataReordering);
    return {type: Constant.SELECT_MANY_SERVICE, dataReordering, totalAmount, totalTime};
}

export function selectService(recordId, stores, dataReordering, selectedTechnicianId) {
    let technician = {id: "", name: ""};
    const allGroupIds = _.uniq(_.map(stores.allServiceStore, (x) => x.service_group_id));
    if (selectedTechnicianId) {
        const technicianFind = stores.priorityTechnicians.find((obj) => {
            return obj.id === selectedTechnicianId;
        });
        if (technicianFind) {
            technician = technicianFind;
        }
    }
    let data = {};
    if (recordId) {
        data = _.find(stores.servicesStore, (item) => item.service_id === recordId);
        if (data) {
            const findSkill = _.find(stores.technicianSkillStore, (x) => x.service_id === data.service_id);
            const techIds = findSkill ? findSkill.technicians : [];
            if (_.indexOf(techIds, technician.id) >= 0) {
                data.technician = technician.id;
                data.technicianName = technician.name;
            } else {
                data.technician = null;
                data.technicianName = null;
            }
            if (dataReordering.findIndex((rec) => rec.service_id === data.service_id) === -1) {
                dataReordering.push(data);
            }
            const diff = _.filter(
                _.differenceBy(stores.allServiceStoreFlatData, dataReordering, "id"),
                (item) => item.service_group_id === data.service_group_id || _.indexOf(allGroupIds, item.service_group_id) >= 0
            );
            stores.servicesStore = _.filter(diff, (x) => x.type !== "service_group");
            stores.allServiceStore = TreeUtil.buildTreeParentId(diff, "id", "parentId", "children");
        }
    }
    const totalAmount = getTotalAmount(dataReordering),
        totalTime = getTotalTime(dataReordering);
    return {type: Constant.SELECT_SERVICE, dataReordering, totalAmount, totalTime};
}

export function selectServiceGroup(service_group_id, stores, dataReordering, selectedTechnicianId) {
    let technician = {id: "", name: ""};
    const allGroupIds = _.uniq(_.map(stores.allServiceStore, (x) => x.service_group_id));
    if (selectedTechnicianId) {
        const technicianFind = stores.priorityTechnicians.find((obj) => {
            return obj.id === selectedTechnicianId;
        });
        if (technicianFind) {
            technician = technicianFind;
        }
    }
    let dataGroup = {};
    if (service_group_id) {
        dataGroup = _.find(stores.allServiceStore, (itemParent) => itemParent.service_group_id === service_group_id);
        if (dataGroup && _.size(dataGroup.children) > 0) {
            _.each(dataGroup.children, (item) => {
                const findSkill = _.find(stores.technicianSkillStore, (x) => x.service_id === item.service_id);
                const techIds = findSkill ? findSkill.technicians : [];
                if (_.indexOf(techIds, technician.id) >= 0) {
                    item.technician = technician.id;
                    item.technicianName = technician.name;
                } else {
                    item.technician = null;
                    item.technicianName = null;
                }
                if (dataReordering.findIndex((rec) => rec.service_id === item.service_id) === -1) {
                    dataReordering.push(item);
                }
            });
            const diff = _.filter(_.differenceBy(stores.allServiceStoreFlatData, dataReordering, "id"), (item) => item.id !== dataGroup.id);
            stores.servicesStore = _.filter(diff, (x) => x.type !== "service_group");
            const newDiff = _.filter(diff, (item) => _.indexOf(allGroupIds, item.service_group_id) >= 0);
            stores.allServiceStore = TreeUtil.buildTreeParentId(newDiff, "id", "parentId", "children");
        }
    }
    const totalAmount = getTotalAmount(dataReordering),
        totalTime = getTotalTime(dataReordering);
    return {type: Constant.SELECT_SERVICE_GROUP, dataReordering, totalAmount, totalTime};
}

export function deselectService(services, stores, dataReordering) {
    // add to all service store
    const servicesId = _.map(services, (x) => x.service_id);
    const newDataReordering = _.filter(dataReordering, (x) => _.indexOf(servicesId, x.service_id) < 0);
    const diff = _.map(_.differenceBy(stores.allServiceStoreFlatData, newDataReordering, "id"), (x) => {
        return {
            ...x,
            selected: false
        };
    });
    const allGroupIds = _.uniq(_.map(stores.allServiceStore, (x) => x.service_group_id));
    const allGroupSelectIds = _.uniq(_.map(services, (x) => x.service_group_id));
    stores.servicesStore = _.filter(diff, (x) => x.type !== "service_group");
    const newDiff = _.filter(diff, (item) => _.indexOf(allGroupSelectIds, item.service_group_id) >= 0 || _.indexOf(allGroupIds, item.service_group_id) >= 0);
    stores.allServiceStore = TreeUtil.buildTreeParentId(newDiff, "id", "parentId", "children");
    const totalAmount = getTotalAmount(newDataReordering);
    const totalTime = getTotalTime(newDataReordering);
    return {type: Constant.DESELECT_SERVICE, dataReordering: newDataReordering, totalAmount, totalTime};
}

export function updateExtraService(extraServices) {
    return {type: Constant.UPDATE_EXTRA_SERVICES, extraServices};
}

export function addNullExtraService(nullExtra) {
    return {type: Constant.ADD_NULL_EXTRA_SERVICES, nullExtra};
}

export function notResetStore(value) {
    return {type: Constant.NOT_RESET_STORE, value};
}

export function updateSelectedServices(dataReordering) {
    const totalAmount = getTotalAmount(dataReordering),
        totalTime = getTotalTime(dataReordering);
    return {type: Constant.UPDATE_SELECTED_SERVICES, dataReordering, totalAmount, totalTime};
}

export function selectMainTechnician(technicianId, dataReordering, stores, extraServices) {
    let technician = null;
    const technicianFind = stores.priorityTechnicians.find((obj) => {
        return obj.id === technicianId;
    });
    if (technicianFind) {
        technician = technicianFind;
    }
    const dataNew = [];
    _.each(dataReordering, (service) => {
        const findSkill = _.find(stores.technicianSkillStore, (x) => x.service_id === service.service_id);
        const techIds = findSkill ? findSkill.technicians : [];
        if (_.indexOf(techIds, technicianId) >= 0) {
            service.technician = technician.id;
            service.technicianName = technician.value;
        } else {
            service.technician = null;
            service.technicianName = null;
        }
        dataNew.push(service);
    });
    _.map(extraServices, (service) => {
        service.technicianName = technician.name;
        service.technician = technicianId;
        if (_.get(service, "type") === "tip") {
            service.name = `TIP for ${service.technicianName}`;
        }
    });
    return {type: Constant.SELECT_MAIN_TECHNICIAN, dataReordering: dataNew, technician, extraServices, technicianId};
}

function getTotalAmount(dataReordering) {
    let total = 0;
    dataReordering.map((service) => {
        total += service.price;
    });
    if (parseFloat(total) < 0) {
        return 0;
    }
    return total;
}

function getTotalTime(dataReordering) {
    let total = 0;
    dataReordering.map((service) => {
        total += service.time;
    });
    return total;
}
