import { t } from "core/idtek-component";

export const BookingStatusContant = {
    STATUS_NEW: 'NEW',
    STATUS_WAITING: 'Waiting',
    STATUS_CHECKIN: 'CheckIn',
    STATUS_PROCESSING: 'Processing',
    STATUS_COMPLETE: 'Completed',
    STATUS_CANCEL: 'Cancel',
    STATUS_NOSHOW: 'NoShow',
    STATUS_PAYMENT_CONFIRM: 'PaymentConfirm',
    STATUS_CANCEL_PAYMENT: "CancelPayment",
    STATUS_APPROVE: 'Approve',
    STATUS_ERROR: 'Error',
    BOOKING_SAME_TECHNICIAN: "BOOKING_SAME_TECHNICIAN",
    BOOKING_TECHNICIAN_NOT_WORKING: "BOOKING_TECHNICIAN_NOT_WORKING",
    BOOKING_BRANCH_NOT_WORKING: "BOOKING_BRANCH_NOT_WORKING",
    BOOKING_CONFLICT: "BOOKING_CONFLICT",
    BOOKING_BRANCH_OFF_HOLIDAY: "BOOKING_BRANCH_OFF_HOLIDAY",
    BOOKING_WAITING_TRANSFER: "WaitingTransfer",
    BOOKING_WAITING_CONFIRM_TRANSFER: "WaitingConfirmTransfer",
};

export const NO_BOOKING = "NoBooking";

export const BookingStatusOption = [
    {
        label: t('BookingStatusContant-PROCESSING'),
        value: BookingStatusContant.STATUS_PROCESSING,
    },
];

export const BookinstStatusUnShowDefault = [
    BookingStatusContant.STATUS_NOSHOW,
    BookingStatusContant.STATUS_CANCEL
]

export const BookingStatusWithLabel = [{
    value: BookingStatusContant.STATUS_WAITING,
    name: t('BookingHistoryListView-WAITING'),
}, {
    value: BookingStatusContant.STATUS_CHECKIN,
    name: t('BookingHistoryListView-CHECK_IN'),
}, {
    value: BookingStatusContant.STATUS_PROCESSING,
    name: t('BookingHistoryListView-PROCESSING'),
}, {
    value: BookingStatusContant.STATUS_COMPLETE,
    name: t('BookingHistoryListView-COMPLETED'),
}, {
    value: BookingStatusContant.STATUS_CANCEL,
    name: t('BookingHistoryListView-CANCEL'),
}, {
    value: BookingStatusContant.STATUS_CANCEL_PAYMENT,
    name: t('BookingHistoryListView-CANCELPAYMENT'),
},
{
    value: BookingStatusContant.STATUS_NOSHOW,
    name: t('BookingHistoryListView-NOSHOW'),
},
{
    value: BookingStatusContant.STATUS_PAYMENT_CONFIRM,
    name: t('BookingHistoryListView-STATUS_PAYMENT_CONFIRM'),
},
{
    value: BookingStatusContant.BOOKING_WAITING_CONFIRM_TRANSFER,
    name: t('BookingHistoryListView-WAITING_CONFIRM_TRANSFER'),
},
{
    value: BookingStatusContant.BOOKING_WAITING_TRANSFER,
    name: t('BookingHistoryListView-WAITING_TRANSFER'),
}]
