export { default as Button } from '@idtek/component-v2/lib/button/IDButton';
export { default as Checkbox } from '@idtek/component-v2/lib/checkbox/IDCheckbox';
export { default as ConfigGlobal } from '@idtek/component-v2/lib/config/IDConfigGlobal';
export { default as Alert } from '@idtek/component-v2/lib/alert/IDAlert';
export { default as Cell } from '@idtek/component-v2/lib/cell/IDCell';
export { default as Container } from '@idtek/component-v2/lib/container/IDContainer';
export { default as Fieldset } from '@idtek/component-v2/lib/fieldset/IDFieldset';
export { default as Avatar } from '@idtek/component-v2/lib/avatar/IDAvatar';
export { default as Collapse } from '@idtek/component-v2/lib/collapse/IDCollapse';
export { default as Row } from '@idtek/component-v2/lib/row/IDRow';
export { default as IDDatePicker } from '@idtek/component-v2/lib/datetime/IDDatePicker';
export { default as Textbox } from '@idtek/component-v2/lib/input/IDInput';
export { default as IDText } from '@idtek/component-v2/lib/text/IDText';
export { default as FullBrowser } from "@idtek/component-v2/lib/full-screen/IDFullBrowser";
export { default as Icon } from '@idtek/component-v2/lib/icon/IDIcon';
export { default as Popover } from '@idtek/component-v2/lib/popover/IDPopover';
export { default as Progress } from '@idtek/component-v2/lib/progress/IDProgress';
export { default as RadioList } from '@idtek/component-v2/lib/radio/IDRadioList';
export { default as Result } from '@idtek/component-v2/lib/result/IDResult';
export { default as Skeleton } from '@idtek/component-v2/lib/skeleton/IDSkeleton';
export { default as Switch } from '@idtek/component-v2/lib/switch/IDSwitch';
export { default as Tabs } from '@idtek/component-v2/lib/tabs/IDTabs';
export { default as Tooltip } from '@idtek/component-v2/lib/tooltip/IDTooltip';
export { default as Modal } from '@idtek/component-v2/lib/modal/IDModal';
export { default as Menu } from '@idtek/component-v2/lib/menu/IDMenu';
export { default as Dropdown } from '@idtek/component-v2/lib/dropdown/IDDropdown';
export { default as Combobox } from '@idtek/component-v2/lib/select/IDSelect';
export { default as TextArea } from '@idtek/component-v2/lib/textarea/IDTextArea';
export { default as Drawer } from '@idtek/component-v2/lib/drawer/IDDrawer';
export { default as Layout } from '@idtek/component-v2/lib/layout/Layout';
export { default as Color } from '@idtek/component-v2/lib/color/IDColorPicker';
export { default as TreeCombo } from '@idtek/component-v2/lib/treecombo/IDTreeCombobox';
export { default as Loading, default as IDLoading } from '@idtek/component-v2/lib/loading/IDLoading';
export { default as Badge } from '@idtek/component-v2/lib/badge/IDBadge';
export { default as Empty } from '@idtek/component-v2/lib/empty/IDEmpty';
export { default as IDInput } from '@idtek/component-v2/lib/input/IDInput';
export { default as Rate } from '@idtek/component-v2/lib/rate/IDRate';
export { default as Slider } from '@idtek/component-v2/lib/slider/IDSlider';
export { default as TimePicker } from '@idtek/component-v2/lib/datetime/IDTimeSingle';
export { default as DatePicker } from '@idtek/component-v2/lib/datetime/IDDatePicker';
export { default as IDList } from '@idtek/component-v2/lib/list/IDList';
export { default as IDSegmented } from '@idtek/component-v2/lib/segmented/IDSegmented';
export { useMergeState } from '@idtek/component-v2/lib/hook/IDHook';

//language
export { t } from '@idtek/language-v2/lib/language';
export { IDLanguageContext as LanguageContext } from '@idtek/language-v2/lib/language/IDLanguageContext'
export { default as LanguageDictionary } from '@idtek/language-v2/lib/language/IDLanguageDictionary'
export { default as LanguageProvider } from '@idtek/language-v2/lib/language/IDLanguageProvider';
export { default as TextTranslate } from '@idtek/language-v2/lib/language/IDTextTranslate';
export { default as Popconfirm } from 'antd/lib/popconfirm';
export { default as DatePickerAntd } from 'antd/lib/date-picker';